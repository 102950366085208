<template>
    <div>
        <v-card class="ma-2 mt-5">
            <g-overlay :is-relay="isOverlay"/>
            <v-row>
                <v-col cols="12" md="8">
                    <v-card class="mx-auto">
                        <v-card-title>Mail Details</v-card-title>
                        <v-divider/>
                        <timeline-component :timeline-data="timelineData" class="ma-5"/>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card class="mx-auto">
                        <v-card-title>Invoice Details</v-card-title>
                        <v-divider/>
                        <v-list dense>
                            <list-item-details symbol="#" title="Invoice ID" :value="invoice.invoiceNo"/>
                            <list-item-details title="Email" :value="invoice.email"/>
                            <list-item-details title="Created Date" :value="getDate(invoice.createdAt)"/>
                            <list-item-payment-status title="Payment Status" :status="invoice.paymentStatus"/>
                            <list-item-details symbol="$" title="Total Amount" :value="invoice.totalAmount"/>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
    import TimelineComponent from "../../components/TimelineComponent";
    import ListItemDetails from "../../components/details/ListItemDetails";
    import ListItemPrepaymentStatus from "../../components/details/ListItemPrepaymentStatus";
    import ListItemPaymentStatus from "../../components/details/ListItemPaymentStatus";
    import GOverlay from "../../components/GOverlay";
    import DateHelper from "../../components/mixins/DateHelper";
    export default {
        name: "MailDetails",
        mixins: [DateHelper],
        components: {
            GOverlay,
            ListItemPaymentStatus,
            ListItemPrepaymentStatus,
            ListItemDetails,
            TimelineComponent
        },
        data() {
            return {
                isOverlay: false,
                invoice: {},
                timelineData: [],
                invoiceId: this.$route.params.id,
                colors: ['cyan', 'green', 'pink', 'amber', 'orange', 'purple', 'indigo', 'light-blue', 'amber', 'lime', 'teal', 'deep-orange', 'brown']
            }
        },
        mounted() {
            this.getMails();
        },
        methods: {
            async getMails() {
                this.isOverlay = true;
                const {data: { data: {item} }} = await this.$store.dispatch('invoice/get', {id: this.invoiceId});
                let {invoiceNo, paymentStatus, email, totalAmount, createdAt, invoice_mails} = item;
                this.invoice = {invoiceNo, paymentStatus, email, totalAmount, createdAt, invoice_mails};
                this.timelineData = invoice_mails.map((item) => {
                    let color = this.pickRandColor();
                    let date = this.getDate(item.created_at);
                    return {
                        color,
                        title: date,
                        subtitle: item.subject,
                        description: item.body
                    }
                });
                this.isOverlay = false;
            },
            pickRandColor() {
                const index = Math.floor(Math.random() * this.colors.length);
                return this.colors[index];
            }
        }
    }
</script>

<style scoped>

</style>